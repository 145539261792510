<template>
  <div class="select-wrapper">
    <template v-if="isGrouped">
      <el-select
        v-bind="$attrs"
        v-model="listInput"
        :size="size"
        :multiple="multiple"
        :popper-class="hideInactive ? 'hide-disabled' : undefined"
        :data-testid="dataTestid"
      >
        <el-option-group v-for="item in normalizedOpts" :key="item.label" :label="item.label">
          <el-option
            v-for="child in item.children"
            :key="child.value"
            :label="child.label"
            :value="child.value"
          ></el-option>
        </el-option-group>
      </el-select>
    </template>
    <template v-else>
      <el-select
        v-bind="$attrs"
        v-model="listInput"
        :size="size"
        :options="normalizedOpts"
        :multiple="multiple"
        :popper-class="hideInactive ? 'hide-disabled' : undefined"
        :data-testid="dataTestid"
      >
        <el-option
          v-for="item in normalizedOpts"
          :key="item.value"
          :value="item.value"
          :label="item.label"
          :class="{ inactive: item.active === false }"
          :disabled="disableInactive ? item.active === false : undefined"
        >
        </el-option>
      </el-select>
    </template>
    <i v-if="clearable" @click="clearInput" class="close-icon el-select__caret el-input__icon el-icon-circle-close"></i>
  </div>
</template>

<script>
import { Select, OptionGroup, Option } from "element-ui";
import { sortBy } from "lodash";

export default {
  name: "v-select",
  components: {
    [Select.name]: Select,
    [OptionGroup.name]: OptionGroup,
    [Option.name]: Option,
  },
  data() {
    return {
      normalizedOpts: [],
    };
  },
  props: {
    size: { type: String, default: "default" },
    dataTestid: { type: String },
    options: { type: Array, default: () => [] },
    disableInactive: { type: Boolean },
    hideInactive: { type: Boolean },
    customSortByField: { type: String },
    valueIdentifier: { type: String, default: "value" },
    labelIdentifier: { type: String, default: "label" },
    value: [String, Number, Array, Boolean],
    isGrouped: Boolean,
    multiple: Boolean,
    clearable: Boolean,
  },
  mounted() {
    if (this.options.length) {
      this.setOptions(this.options);
    }
  },
  methods: {
    setOptions(opts = []) {
      const sortByField = this.customSortByField ? this.customSortByField : this.labelIdentifier;
      this.normalizedOpts = sortBy(
        opts.map((option) => ({
          value: option[this.valueIdentifier],
          label: option[this.labelIdentifier],
          ...option,
        })),
        (o) => `${o[sortByField]}`.toLowerCase()
      );
    },
    reduceSelection(selection) {
      return selection.value;
    },
    clearInput() {
      if (this.multiple) {
        this.listInput = [];
      } else {
        this.listInput = null;
      }
    },
    // handleChange(data) {
    //   this.$emit("change", data);
    // },
  },
  computed: {
    listInput: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
        this.$emit("change", newValue);
      },
    },
  },
  watch: {
    options(newVal, oldVal) {
      if (newVal) {
        this.setOptions(newVal);
      }
    },
  },
};
</script>

<style lang="scss">
.select-wrapper {
  .el-select {
    display: inline-block;
    width: 100%;
  }
  .close-icon {
    z-index: 100;
    display: none;
    top: 0;
    cursor: pointer;
  }
  position: relative;
  &:hover .close-icon {
    display: inline-block;
    position: absolute;
    right: 25px;
  }
  &[disabled]:hover {
    .close-icon {
      display: none;
    }
  }
}
.inactive {
  opacity: 0.45;
}
.hide-disabled .el-select-dropdown__item.is-disabled {
  display: none;
}
li.el-select-group__title {
  color: #727272;
}
</style>
