<template>
  <div class="n-edit-wrapper">
    <ValidationObserver ref="validateObserver" v-slot="{ handleSubmit }">
      <el-row type="flex">
        <el-col :span="24">
          <el-breadcrumb class="n-view-breadcrumb" separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">{{
              $t("src.components.serviceprovider.edit.einkauf")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/purchasing/serviceProvider/index' }">{{
              $t("src.components.serviceprovider.edit.dienstleister")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ serviceProvider.name }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ activeNameLabel }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <el-row type="flex" class="mb-2">
        <el-col :span="12">
          <div class="n-view-title">{{ serviceProvider.name }}</div>
        </el-col>
        <el-col :span="12" class="text-right" style="display: flex; align-items: center; justify-content: flex-end">
          <cancel-button icon="el-icon-arrow-left" @click="handleBack">{{
            $t("src.components.serviceprovider.edit.zurck")
          }}</cancel-button>
          <template v-if="isEditMode">
            <cancel-button v-if="$route.params.id === 'new'" @click="deleteProfile()" />
            <cancel-button v-if="$route.params.id !== 'new'" @click="changeEditMode(false)">{{
              $t("src.components.serviceprovider.edit.editierenBeenden")
            }}</cancel-button>
            <delete-button v-if="$route.params.id !== 'new'" @click="deleteProfile()" />
            <save-button @click="handleSubmit(updateProfile(serviceProvider))" data-testid="save_profile" />
          </template>
          <template v-else>
            <edit-profile-button
              v-if="$can('update', 'service_provider')"
              :editMode="isEditMode"
              @click="changeEditMode(!isEditMode)"
            />
          </template>
        </el-col>
      </el-row>
      <el-tabs v-model="activeName" tab-position="left" class="profile-tabs hide-disabled">
        <el-tab-pane :label="$t('src.components.serviceprovider.edit.general')" name="general">
          <perfect-scrollbar :options="{ suppressScrollX: true }">
            <serviceProvider-profile
              :isEditMode="isEditMode"
              :serviceProvider="serviceProvider"
              :tabName="activeName"
              v-on:updateProfileCicked="updateProfile"
            />
          </perfect-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="$t('src.components.serviceprovider.edit.documents')" name="documents" v-if="!isNew">
          <perfect-scrollbar :options="{ suppressScrollX: true }">
            <document-list
              @updateResource="updateProfile"
              :isEditMode="isEditMode"
              modelType="serviceProvider"
              :serviceProviderType="serviceProvider.serviceProviderType"
              :modelID="serviceProvider.id"
              :tabName="activeName"
              :accessRights="providerAccess.generalAccess"
            />
          </perfect-scrollbar>
        </el-tab-pane>
      </el-tabs>
    </ValidationObserver>
    <serviceProvider-profile-settings v-show="false" />
  </div>
</template>

<script>
import Profile from "./Profile.vue";
import { MessageBox, Message, Tabs, TabPane } from "element-ui";
import { Documents } from "src/components/UIComponents";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import ProfileSettings from "./ProfileSettings";
import { ValidationObserver } from "vee-validate";
import { unlink } from "src/utils/unlink";
import { mapActions, mapState } from "vuex";
import { get } from "lodash";

export default {
  mounted() {
    const id = this.$route.params.id; //coming from routing parameter
    const identifier = this.$route.meta.identifier;
    //create or edit mode - edit possible
    if (identifier === "serviceProvider_edit" || id === "new") {
      this.changeEditMode(true);
    } else {
      //view mode - no editing possible
      this.changeEditMode(false);
    }
    if (this.$route.query.tab_pane) {
      this.activeName = this.$route.query.tab_pane;
    }
    if (id === "new") {
      setTimeout(() => {
        this.setPreservedData(this.serviceProvider);
        this.checkDirtyFlag(this.serviceProvider);
      }, 1000);
    } else {
      const route = `/api/service-providers/${id}`;
      //get data from api REST call
      this.axios
        .get(route)
        .then((result) => {
          //set user data loaded from database
          if (result.data.extendedProps && result.data.extendedProps.price == 0) {
            result.data.extendedProps.price = "";
          }
          this.serviceProvider = result.data;
          this.originalData = unlink(result.data);
          this.setPreservedData(result.data);
          this.checkDirtyFlag(result.data);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    }
  },
  components: {
    ValidationObserver,
    MessageBox,
    Message,
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    [Documents.name]: Documents,
    [Profile.name]: Profile,
    PerfectScrollbar,
    [ProfileSettings.name]: ProfileSettings,
  },
  data() {
    return {
      activeName: "general",
      originalData: null,
      serviceProvider: {
        hrContact: {},
        extendedProps: {},
        inital: true,
        active: true,
      },
    };
  },
  beforeRouteLeave(route, redirect, next) {
    let self = this;
    if (this.isDirty && this.$route.meta.identifier === "serviceProvider_edit") {
      MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        distinguishCancelAndClose: true,
        confirmButtonClass: "el-button--success",
      })
        .then(function (action) {
          self.updateProfile(self.serviceProvider, false);
          next();
        })
        .catch(function (action) {
          if (action === "close") {
            next(false);
          } else {
            next();
          }
        });
    } else {
      next();
    }
  },
  methods: {
    ...mapActions({
      setPreservedData: "dirtyFlag/setPreservedData",
      checkDirtyFlag: "dirtyFlag/checkDirty",
      discardDirty: "dirtyFlag/discard",
    }),
    ...mapActions("profileEditMode", ["changeEditMode"]),
    async updateProfile(entity, redirectOnCreate = true) {
      if (!(await this.$refs.validateObserver.validate())) {
        Message({
          message: "Bitte füllen Sie alle erforderlichen Felder aus",
          type: "error",
        });
        return;
      }
      const isNewProfile = this.$route.params.id === "new";
      try {
        const route = "/api/service-providers";
        if (isNewProfile) {
          const response = await this.axios.post(route, entity);
          this.serviceProvider = response.data;
          this.originalData = unlink(response.data);
          if (redirectOnCreate) {
            this.$router.push({
              name: "ServiceProvider Edit",
              params: { id: response.data.id },
            });
          }
        } else {
          const response = await this.axios.put(`${route}/${entity.id}`, entity);
          this.originalData = unlink(response.data);
        }
        this.$nextTick(() => {
          this.setPreservedData(this.originalData);
          this.checkDirtyFlag(this.originalData);
        });
        Message({
          message: "Dienstleister Daten gespeichert",
          type: "success",
        });
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    deleteProfile() {
      this.$confirmDelete().then(() => {
        this.axios.delete(`/api/service-providers/${this.serviceProvider.id}`);
        this.discardDirty();
        this.$router.push({
          name: "ServiceProvider Index",
        }); //directly go back to index page
      });
    },
    handleBack() {
      this.$router.push({ name: "ServiceProvider Index" });
    },
  },
  computed: {
    ...mapState("dirtyFlag", { isDirty: "isDirty" }),
    ...mapState("profileEditMode", ["isEditMode"]),
    ...mapState("account", { accessRights: "accessRights" }),
    activeNameLabel() {
      if (this.activeName === "0") {
        return "";
      }
      return this.$t(`src.components.serviceprovider.edit.${this.activeName}`);
    },
    providerAccess() {
      return get(this.accessRights, "service_provider", { specificAccess: {}, generalAccess: null });
    },
    isNew() {
      return this.$route.params.id === "new";
    },
  },
  watch: {
    isEditMode(newVal) {
      if (!newVal) {
        this.serviceProvider = unlink(this.originalData);
      }
    },
    serviceProvider: {
      handler(newVal) {
        this.checkDirtyFlag(newVal);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
