<template>
  <div style="height: 100%">
    <div v-if="data" class="todo-editor-wrapper" v-loading="todoLoading">
      <div class="todo-editor-header">
        <div style="flex-grow: 1" v-if="data.isNew">
          <ValidationObserver ref="validateNewObserver" slim>
            <div class="row">
              <div class="col-lg-6 col-xl-4">
                <profile-select
                  :title="$t('Priority')"
                  :name="$t('Priority')"
                  rules="required"
                  :editMode="true"
                  :clearable="false"
                  :multiple="false"
                  v-model="data.priority"
                  :items="priorityOptions"
                  required
                  size="small"
                />
              </div>
              <div class="col-lg-6 col-xl-4">
                <profile-select
                  :title="$t('Responsible')"
                  :name="$t('Responsible')"
                  rules="required"
                  :editMode="true"
                  :clearable="false"
                  :multiple="false"
                  v-model="data.responsibleId"
                  label="name"
                  filterable
                  valueIdentifier="_id"
                  :items="projectMembers"
                  isGrouped
                  required
                  size="small"
                />
              </div>
              <div class="col-lg-6 col-xl-4">
                <profile-date-picker
                  v-model="data.dueDate"
                  :title="$t('Deadline')"
                  :isEditMode="true"
                  :name="$t('Deadline')"
                  size="small"
                />
              </div>
            </div>
          </ValidationObserver>
        </div>
        <template v-else>
          <div style="flex-grow: 1">
            <div class="d-flex align-items-center mb-2">
              <todo-status :status="data.status" class="mr-1" />
              <todo-priority :priority="data.priority" class="mr-1" />
              <todo-ticket-number v-if="data.ticketNumber" :ticketNumber="data.ticketNumber" />
            </div>
            <todo-progressbar :data="data" :is-workshop="isWorkshop" />
          </div>
          <todo-editor-dropdown
            :data="data"
            :isPrivilegedUser="isPrivilegedUser"
            :priorityOptions="priorityOptions"
            :projectMembers="projectMembers"
            :isWorkshop="isWorkshop"
            @setStatus="handleChangeStatus"
            @delete="handleDeleteTodo"
            @editRecord="handleEditRecord"
            @exportPdf="$emit('exportPdf')"
          />
        </template>
      </div>
      <div class="todo-editor-body d-flex flex-column flex-grow-1">
        <div class="mb-2 position-relative">
          <template v-if="computedEditable">
            <ValidationObserver ref="validateTitleObserver" slim>
              <profile-input
                size="small"
                :placeholder="$t('Title')"
                name="Titels der Aufgabe"
                rules="required"
                no-label
                :editMode="computedEditable"
                :showErrorText="false"
                v-model="data.title"
              />
            </ValidationObserver>
            <div style="position: absolute; bottom: 0; left: 0">Beschreibung und Titel sind Pflichtfelder</div>
          </template>
          <b v-else style="font-size: 15px">{{ data.title }}</b>
        </div>
        <div v-if="computedEditable" class="d-flex flex-column flex-grow-1 mb-2" style="min-height: 400px">
          <ValidationObserver ref="validateBodyObserver" slim>
            <todo-wysiwyg v-model="data.body" name="Beschreibung der Aufgabe" rules="required" />
          </ValidationObserver>
        </div>
        <div class="mb-2" v-else v-html="data.body" />
        <drawer-upload
          :disabled="!computedEditable"
          :data="{ metadata: getMetadata() }"
          location="TODOS"
          :before-upload="handleBeforeUpload"
          :on-remove="removeFile"
          :on-success="fileUploaded"
          :file-list="data.attachments"
          class="mb-2"
          :pictureList="true"
        />
        <todo-content
          v-if="data.projectId"
          :data="data.content"
          :isOwner="data.isOwner"
          :isPrivilegedUser="isPrivilegedUser"
          :projectId="data.projectId"
          @editTodo="editContent"
          @deleteTodo="deleteContent"
        />
        <div class="todo-editor-footer" v-if="computedEditable || data.isResponsible || isWorkshop">
          <div class="d-flex justify-space-between" v-if="computedEditable">
            <div>
              <el-button
                type="danger"
                v-if="!data.isNew && (data.isOwner || isPrivilegedUser)"
                @click="handleDeleteTodo"
                style="margin-right: auto"
              >
                {{ $t("Delete") }}
              </el-button>
              <el-button @click="$emit('cancel')">{{ $t("Cancel") }}</el-button>
            </div>
            <div style="margin-left: auto">
              <el-button type="primary" @click.prevent="submit()" :loading="saving">{{ $t("Save") }}</el-button>
            </div>
          </div>
          <template v-else-if="data.isResponsible || isWorkshop">
            <el-button type="primary" @click="addContent">{{ $t("Add comment") }}</el-button>
          </template>
        </div>
      </div>
      <el-dialog
        ref="dialog"
        :title="childTodoTitle"
        :visible.sync="isVisible"
        @closed="discard"
        :before-close="handleClose"
        width="540px"
      >
        <ValidationObserver ref="validateContent" slim>
          <div class="mb-3">
            <todo-wysiwyg v-model="currentContent.body" />
          </div>
          <drawer-upload
            :disabled="!computedEditable"
            :data="{ metadata: getMetadata() }"
            :on-remove="removeChildFile"
            :on-success="childFileUploaded"
            :file-list="currentContent.attachments"
            :before-upload="handleBeforeUpload"
            :pictureList="true"
            location="TODOS"
            class="mb-2"
          />
          <div class="d-flex justify-content-between">
            <el-button @click="discard">{{ $t("Cancel") }}</el-button>
            <el-button type="primary" @click="saveContent">{{ $t("Save") }}</el-button>
          </div>
        </ValidationObserver>
      </el-dialog>
    </div>
    <div v-else class="empty-data">Kein Todo ausgewählt</div>
  </div>
</template>

<script>
import DrawerUpload from "../Invoices/DrawerUpload.vue";
import { ValidationObserver } from "vee-validate";
import { Message, Dialog, MessageBox } from "element-ui";
import TodoContent from "./TodoContent.vue";
import { unlink } from "src/utils/unlink";
import { mapState } from "vuex";
import TodoWysiwyg from "./TodoWysiwyg.vue";
import TodoStatus from "./TodoStatus.vue";
import TodoPriority from "./TodoPriority.vue";
import TodoProgressbar from "./TodoProgressbar.vue";
import TodoTicketNumber from "./TodoTicketNumber.vue";
import ProfileDatePicker from "../../UIComponents/Inputs/ProfileDatePicker.vue";
import TodoEditorDropdown from "./TodoEditorDropdown.vue";
import { resizeImage } from "src/utils/resizeImage";
import { dataURLtoFile } from "src/utils/dataURLtoFile";
import { last } from "lodash";

export default {
  name: "todo-editor",
  props: {
    data: { type: Object },
    isWorkshop: { type: Boolean },
    projectMembers: { type: Array, default: () => [] },
    todoLoading: { type: Boolean },
    projectId: { type: String },
    isPrivilegedUser: { type: Boolean },
    isEditMode: { type: Boolean },
  },
  components: {
    ValidationObserver,
    DrawerUpload,
    TodoContent,
    TodoWysiwyg,
    TodoStatus,
    TodoPriority,
    TodoProgressbar,
    TodoTicketNumber,
    ProfileDatePicker,
    TodoEditorDropdown,
    [MessageBox.name]: MessageBox,
    [Message.name]: Message,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      timestamp: new Date().getTime(),
      isVisible: false,
      saving: false,
      currentContent: {},
      priorityOptions: [
        { label: this.$t("High"), value: 2 },
        { label: this.$t("Medium"), value: 1 },
        { label: this.$t("Low"), value: 0 },
      ],
    };
  },
  methods: {
    async handleBeforeUpload(file) {
      try {
        const imageFormats = ["jpg", "jpeg", "png", "gif"];
        const ext = last(file.name.split(".")).toLowerCase();
        const supportedImageFormat = imageFormats.find((item) => item === ext);
        const isGt2M = file.size / 1024 / 1024 > 2;
        if (supportedImageFormat && isGt2M) {
          const objectUrl = URL.createObjectURL(file);
          const img = await resizeImage(objectUrl, {
            maxWidth: 1024,
            maxHeight: 768,
            contentType: `image/${supportedImageFormat}`,
            quality: 0.7,
          });
          const newFile = dataURLtoFile(img.url, file.name);
          return newFile;
        }
        return file;
      } catch (error) {
        console.log("error.message", error.message);
        throw error;
      }
    },
    getMetadata() {
      return `project_${this.data.projectId}_todos_0`;
    },
    fileUploaded(response, file, fileList) {
      this.data.attachments = this.data.attachments.concat(response);
    },
    childFileUploaded(response, file, fileList) {
      this.currentContent.attachments = [].concat(this.currentContent.attachments, response).filter(Boolean);
    },
    async removeFile(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.data.attachments = this.data.attachments.filter((item) => item._id !== file._id);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async removeChildFile(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.currentContent.attachments = this.currentContent.attachments.filter((item) => item._id !== file._id);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async submit(silent = false) {
      try {
        let isInputsValid = true;
        let isBodyValid = true;
        let isTitleValid = true;
        this.saving = true;
        if (this.$refs.validateNewObserver) {
          isInputsValid = await this.$refs.validateNewObserver.validate();
        }
        if (this.$refs.validateTitleObserver) {
          isTitleValid = await this.$refs.validateTitleObserver.validate();
        }
        if (this.$refs.validateBodyObserver) {
          isBodyValid = await this.$refs.validateBodyObserver.validate();
        }
        if (!isBodyValid || !isInputsValid || !isTitleValid) {
          this.saving = false;
          return;
        }

        const { isNew, isOwner, ...formData } = this.data;
        // if (formData.projectId === this.projectId) {
        //   delete formData.projectId;
        // }
        let response;
        if (this.data.isNew) {
          response = await this.axios.post(`/api/todo-list`, formData);
        } else {
          response = await this.axios.put(`/api/todo-list/${this.data._id}`, formData);
        }
        if (silent === false) {
          this.$emit("onTodoSubmit", response.data, isNew);
        }
        requestAnimationFrame(() => {
          if (this.$refs.validateNewObserver) {
            this.$refs.validateNewObserver.reset();
          }
          if (this.$refs.validateBodyObserver) {
            this.$refs.validateBodyObserver.reset();
          }
          if (this.$refs.validateTitleObserver) {
            this.$refs.validateTitleObserver.reset();
          }
        });
        return response.data;
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.saving = false;
      }
    },
    editContent(todo) {
      this.isVisible = true;
      this.currentContent = unlink(todo);
      if (!this.currentContent.attachments) {
        this.currentContent.attachments = [];
      }
    },
    handleDeleteTodo() {
      this.$emit("removeTodo");
    },
    deleteContent(todoId) {
      this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/todo-list/${this.data._id}/${todoId}`);
          const newTodos = this.data.content.filter((item) => item._id !== todoId);

          this.$nextTick(() => {
            this.$emit("updateContents", newTodos);
          });
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    addContent() {
      this.isVisible = true;
      this.currentContent = {
        isNew: true,
        projectId: this.data.projectId,
        attachments: [],
      };
    },
    async saveContent() {
      try {
        const isValid = await this.$refs.validateContent.validate();
        if (!isValid) {
          return;
        }
        let response;
        const formBody = { ...this.currentContent, projectId: this.data.projectId };
        if (this.currentContent.isNew) {
          response = await this.axios.post(`/api/todo-list/project/${this.projectId}/${this.data._id}`, formBody);
        } else {
          response = await this.axios.put(`/api/todo-list/${this.data._id}/${this.currentContent._id}`, formBody);
        }
        this.$emit("updateContents", response.data);
        this.discard();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    handleClose(done) {
      let shouldPrompt = false;
      if (this.currentContent.isNew) {
        if (this.currentContent.body && this.currentContent.body.length) {
          shouldPrompt = true;
        }
      } else {
        if (
          this.currentContent.body !== this.data.body ||
          (this.currentContent.attachments &&
            this.data.attachments &&
            this.currentContent.attachments.length !== this.data.attachments.length)
        ) {
          shouldPrompt = true;
        }
      }
      if (shouldPrompt) {
        MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
          distinguishCancelAndClose: true,
          confirmButtonClass: "el-button--success",
        })
          .then(() => {
            this.saveContent();
            done();
          })
          .catch(() => {
            done();
          });
      } else {
        this.discard();
      }
    },
    discard() {
      this.isVisible = false;
      this.currentContent = {};
    },
    handleChangeStatus({ status }) {
      this.data.status = status;
      this.$nextTick(() => {
        this.submit();
      });
    },
    handleEditRecord(payload) {
      this.data.responsibleId = payload.responsibleId;
      this.data.priority = payload.priority;
      this.data.dueDate = payload.dueDate;
      this.data.workshopFreeText = payload.workshopFreeText;
      this.data.attachments = payload.attachments;
      this.data.body = payload.body;
      this.data.title = payload.title;
      this.$nextTick(() => {
        this.submit();
      });
    },
  },
  watch: {
    data(newVal, oldVal) {
      if (newVal) {
        if (!oldVal || oldVal._id !== newVal._id) {
          if (this.$refs.validateBodyObserver) {
            this.$refs.validateBodyObserver.reset();
          }
          this.timestamp = new Date().getTime();
        }
      }
    },
  },
  computed: {
    ...mapState("account", ["user"]),
    childTodoTitle() {
      if (this.currentContent._id) {
        return this.$t("Edit comment");
      } else {
        return this.$t("New comment");
      }
    },
    computedEditable() {
      return this.isEditMode && (this.data.isOwner || this.isPrivilegedUser);
    },
  },
};
</script>

<style></style>
