<template>
  <div class="mb-10">
    <el-form inline style="display: flex; align-items: flex-start">
      <ValidationObserver ref="validateObserver">
        <el-form-item>
          <profile-price-input
            v-model="orderValue"
            label="Auftragswert Bauabschnitt"
            name="Auftragswert Bauabschnitt"
            :editMode="isEditMode"
          />
          <profile-price-input
            v-model="costsExcluded"
            ref="costsInput"
            label="Auftragswert exkl. Zukäufe"
            name="Auftragswert exkl. Zukäufe"
            :editMode="isEditMode"
          />
        </el-form-item>
        <el-form-item>
          <profile-price-input
            v-model="targetTurnOver"
            :label="$t('src.components.project.projectcontrolling.projectcontrolling.targetTurnOver')"
            :name="$t('src.components.project.projectcontrolling.projectcontrolling.targetTurnOver')"
            :editMode="isEditMode"
          />
        </el-form-item>
      </ValidationObserver>
      <template v-if="isConstructionPhase">
        <el-form-item>
          <profile-price-input
            v-model="totalOrderValue"
            label="Auftragswert Gesamtprojekt"
            :name="$t('src.components.project.projectcontrolling.projectcontrolling.targetTurnOver')"
            :editMode="isEditMode"
          />
        </el-form-item>
        <el-form-item>
          <profile-price-input
            ref="totalCostsInput"
            v-model="totalOrderValueCostsExcluded"
            label="Auftragsw. Gesamtpr. exkl. Zukäufe"
            name="Auftragsw. Gesamtpr. exkl. Zukäufe"
            :editMode="isEditMode"
          />
        </el-form-item>
      </template>
      <!-- <el-form-item v-if="isEditMode"> -->
      <el-button style="margin-top: 40px" v-if="isEditMode" type="primary" @click="handleSave">{{
        $t("submit")
      }}</el-button>
      <!-- </el-form-item> -->
    </el-form>
    <div style="display: flex; align-items: center; justify-content: flex-start">
      <el-button
        type="primary"
        v-if="isEditMode"
        @click="showAddProgress"
        style="margin-bottom: 22px; margin-right: 10px"
        >{{ $t("Add progress") }}</el-button
      >
      <router-link
        class="el-button el-button--primary"
        :to="{ name: 'Dashboard', params: { tab_pane: 'financeReport' } }"
        style="margin-bottom: 22px"
      >
        {{ $t("View Finance report") }}
      </router-link>
    </div>

    <el-table :data="fulfillment" style="width: 100%" :default-sort="{ prop: 'percent', order: 'ascending' }">
      <el-table-column
        type="index"
        :label="$t('src.components.project.projectcontrolling.costs.nr')"
        sortable
        width="60"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.project.projectcontrolling.projectcontrolling.percent')"
        min-width="180"
        prop="percent"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.project.projectcontrolling.projectcontrolling.date')"
        min-width="180"
        prop="date"
        :formatter="dateFormatter"
      ></el-table-column>
      <el-table-column :label="$t('src.components.project.projectcontrolling.costs.aktion')" fixed="right" width="150">
        <template slot-scope="scope" v-if="isEditMode">
          <el-button class="btn btn-sm btn-ghost" @click="handleEditRecord(scope.row, scope.$index)">
            <pencil-icon />
          </el-button>
          <el-button class="btn btn-sm btn-ghost" @click="handleDeleteRecord(scope.row._id)">
            <trash-can-outline-icon />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-divider />
    <el-dialog :visible.sync="visible" :title="modalTitle" @closed="discard" width="650px">
      <el-form :model="formData" :rules="formRules" ref="addFulfillmentForm">
        <div class="d-flex">
          <div style="flex-shrink: 0; margin-right: 10px">
            <el-form-item prop="percent">
              <profile-input
                style="width: 150px"
                type="number"
                required
                :editMode="true"
                v-model="formData.percent"
                :title="$t('src.components.project.projectcontrolling.projectcontrolling.percent')"
                :label="$t('src.components.project.projectcontrolling.projectcontrolling.percent')"
              >
                <template v-slot:suffix>
                  <span>%</span>
                </template>
              </profile-input>
            </el-form-item>
          </div>
          <div style="flex-grow: 1; margin-right: 10px">
            <el-form-item prop="date">
              <profile-date-picker
                :projectDateRange="projectDateRange"
                :isEditMode="true"
                v-model="formData.date"
                :title="$t('src.components.project.projectcontrolling.projectcontrolling.date')"
                :placeholder="$t('src.components.project.projectcontrolling.projectcontrolling.date')"
                value-format="YYYY-MM-DD"
                required
              />
            </el-form-item>
          </div>
          <div class="d-flex" style="flex-shrink: 0; align-items: flex-end; margin-bottom: 22px">
            <el-button type="primary" @click="handleSubmit">{{ $t("submit") }}</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { Form, FormItem, Message, Table, TableColumn, Dialog, Divider } from "element-ui";
import { get, last } from "lodash";
import ProfilePriceInput from "../../UIComponents/Inputs/ProfilePriceInput.vue";
import { ValidationObserver } from "vee-validate";
import ProfileInputNumber from "../../UIComponents/Inputs/ProfileInputNumber.vue";
import { moment } from "src/config/moment";

export default {
  name: "project-fulfillment",
  components: {
    ValidationObserver,
    ProfilePriceInput,
    ProfileInputNumber,
    [Dialog.name]: Dialog,
    [Divider.name]: Divider,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [Message.name]: Message,
    [FormItem.name]: FormItem,
  },
  props: {
    projectDateRange: Array,
    isConstructionPhase: Boolean,
    projectId: String,
    isEditMode: Boolean,
  },
  data() {
    return {
      loading: false,
      visible: false,
      fetchedOrderValue: null,
      editingRecordIdx: null,
      orderValue: null,
      targetTurnOver: null,
      costsExcluded: null,
      totalOrderValue: null,
      totalOrderValueCostsExcluded: null,
      costsValue: null,
      totalCostsValue: null,
      fulfillment: [],
      formData: {
        date: null,
        percent: null,
      },
      formRules: {
        date: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("Bitte Datum zum Projektfortschritt eintragen"));
              }
              if (typeof this.editingRecordIdx === "number") {
                return callback();
              }
              const lastRecord = last(this.fulfillment);
              if (lastRecord && moment(lastRecord.date).isAfter(value, "day")) {
                return callback(new Error("Datum muss nach dem vorherigen Status-Datum liegen"));
              }
              if (value < 1 || value > 100) {
                return callback(new Error("Sollte zwischen 1 und 100 liegen"));
              }
              callback();
            },
          },
        ],
        percent: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("Bitte Projektfortschritt in Prozent eintragen"));
              }
              if (value < 1 || value > 100) {
                return callback(new Error("Sollte zwischen 1 und 100 liegen"));
              }
              callback();
            },
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const response = await this.axios.get(`/api/projects/${this.projectId}/order-value`);
        this.fetchedOrderValue = response.data.orderValue;
        this.orderValue = response.data.orderValue;
        this.targetTurnOver = response.data.targetTurnOver;
        this.totalOrderValue = response.data.totalOrderValue;
        this.costsExcluded = response.data.costsExcluded;
        this.totalOrderValueCostsExcluded = response.data.totalOrderValueCostsExcluded;
        this.costsValue = response.data.orderValue - response.data.costsExcluded;
        this.totalCostsValue = response.data.totalOrderValue - response.data.totalOrderValueCostsExcluded;
        const fulfillmentResponse = await this.axios.get(`/api/project-fulfillment/${this.projectId}`);
        this.fulfillment = fulfillmentResponse.data;
      } catch (error) {
        throw error;
      }
    },
    async handleSave() {
      try {
        const isValid = await this.$refs.validateObserver.validate();
        if (!isValid) {
          return;
        }
        await this.axios.post(`/api/projects/${this.projectId}/order-value`, {
          orderValue: this.orderValue,
          targetTurnOver: this.targetTurnOver,
          totalOrderValue: this.totalOrderValue,
          costsExcluded: this.costsExcluded,
          totalOrderValueCostsExcluded: this.totalOrderValueCostsExcluded,
          orderValueIsSetFromInvoice: this.orderValue === this.fetchedOrderValue,
        });
        this.fetchedOrderValue = this.orderValue;
        Message.success("Gespeichert");
      } catch (error) {
        throw error;
      }
    },
    showAddProgress() {
      this.visible = true;
      this.formData.date = moment().format("YYYY-MM-DD");
    },
    handleSubmit() {
      this.$refs.addFulfillmentForm.validate(async (valid) => {
        if (valid) {
          try {
            const payload = {
              ...this.formData,
              percent: +this.formData.percent,
              projectId: this.projectId,
            };
            if (typeof this.editingRecordIdx === "number") {
              const id = this.fulfillment[this.editingRecordIdx]._id;
              const response = await this.axios.put(`/api/project-fulfillment/${id}`, payload);
              this.fulfillment.splice(this.editingRecordIdx, 1, response.data);
              Message.success("Fortschritt bearbeitet");
            } else {
              const response = await this.axios.post(`/api/project-fulfillment`, payload);
              this.fulfillment.push(response.data);
              Message.success("Fortschritt hinzugefügt");
            }
            this.discard();
          } catch (error) {
            const message = get(error, "response.data.message", error.message);
            Message.error(message);
            throw error;
          }
        }
      });
    },
    handleEditRecord(record, idx) {
      this.editingRecordIdx = idx;
      this.visible = true;
      this.formData.percent = record.percent;
      this.formData.date = record.date;
    },
    async handleDeleteRecord(id) {
      try {
        await this.axios.delete(`/api/project-fulfillment/${id}`);
        this.fulfillment = this.fulfillment.filter((item) => item._id !== id);
      } catch (error) {
        const message = get(error, "response.data.message", error.message);
        Message.error(message);
        throw error;
      }
    },
    discard() {
      this.editingRecordIdx = null;
      this.visible = false;
      this.$refs.addFulfillmentForm.resetFields();
    },
    dateFormatter(record) {
      if (record.date) {
        return moment(record.date).format("L");
      }
      return "N/A";
    },
    /* This method is triggered by ref from ProjectControlling to update the value */
    setCosts(costsValue) {
      const newValue = (this.orderValue || 0) - costsValue;
      this.costsExcluded = newValue;
      this.$refs.costsInput.updateValue(newValue);
      this.costsValue = (this.orderValue || 0) - newValue;
    },
    setTotalCosts(costsValue) {
      const newValue = (this.totalOrderValue || 0) - costsValue;
      this.totalOrderValueCostsExcluded = newValue;
      this.$refs.totalCostsInput.updateValue(newValue);
      this.totalCostsValue = (this.totalOrderValue || 0) - newValue;
    },
  },
  computed: {
    modalTitle() {
      if (typeof this.editingRecordIdx === "number") {
        return this.$t("Edit progress");
      } else {
        return this.$t("Add progress");
      }
    },
  },
  filters: {
    formatPrice: function (price) {
      if (price) {
        return parseFloat(price).toLocaleString("de-DE") + " €";
      } else {
        return "-";
      }
    },
  },
  watch: {
    orderValue(newVal, oldVal) {
      if (oldVal !== null && newVal && this.costsExcluded) {
        this.costsExcluded = (newVal || 0) - (this.costsValue || 0);
        this.$nextTick(() => {
          this.$refs.costsInput.updateValue(this.costsExcluded);
        });
      }
    },
    totalOrderValue(newVal, oldVal) {
      if (oldVal !== null && newVal && this.totalOrderValueCostsExcluded) {
        this.totalOrderValueCostsExcluded = (newVal || 0) - this.totalCostsValue;
        this.$nextTick(() => {
          this.$refs.totalCostsInput.updateValue(this.totalOrderValueCostsExcluded);
        });
      }
    },
  },
};
</script>

<style></style>
