<template>
  <el-dialog title="Projekt zuweisen" width="700px" :visible="visible" center :show-close="false" @close="close">
    <el-form label-width="120px" ref="eventForm" :model="formData" :rules="formDataRules">
      <el-form-item
        :label="$t('src.components.project.bryntumscheduler.projecteventcreateeditor.projekt')"
        prop="selectedProject"
        :class="{ 'mb-0': resourceType !== 'supply' }"
      >
        <el-select
          v-model="formData.selectedProject"
          :placeholder="$t('src.components.project.bryntumscheduler.projecteventcreateeditor.projektAuswhlen')"
        >
          <el-option
            v-for="item in availableProjects"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="completeRemainingProject" class="mb-0">
        <el-checkbox v-model="formData.completeRemainingProject">{{
          $t("src.components.project.bryntumscheduler.projecteventcreateeditor.completeRemainingProject")
        }}</el-checkbox>
      </el-form-item>
      <el-form-item
        :label="$t('src.components.project.bryntumscheduler.projecteventcreateeditor.wochentage')"
        prop="checkedWeekDays"
      >
        <el-checkbox-group v-model="formData.checkedWeekDays">
          <el-checkbox :label="1" :disabled="formData.disabledWeekDays.includes(1)">Mo</el-checkbox>
          <el-checkbox :label="2" :disabled="formData.disabledWeekDays.includes(2)">Di</el-checkbox>
          <el-checkbox :label="3" :disabled="formData.disabledWeekDays.includes(3)">Mi</el-checkbox>
          <el-checkbox :label="4" :disabled="formData.disabledWeekDays.includes(4)">Do</el-checkbox>
          <el-checkbox :label="5" :disabled="formData.disabledWeekDays.includes(5)">Fr</el-checkbox>
          <el-checkbox :label="6" :disabled="formData.disabledWeekDays.includes(6)">Sa</el-checkbox>
          <el-checkbox :label="7" :disabled="formData.disabledWeekDays.includes(7)">So</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('Quantity')" prop="quantity" class="mb-0" v-if="resourceType === 'supply'">
        <div class="flex align-items-center">
          <el-input-number v-model="formData.quantity" />
          <span style="margin-left: 10px">{{ unitName }}</span>
        </div>
      </el-form-item>
      <el-row v-if="previousAssignments.length" :gutter="10">
        <el-col :span="8" v-for="(assignment, idx) in previousAssignments" :key="idx">
          <el-card shadow="never" class="mb-2" :body-style="{ padding: '10px' }" style="border-color: #f88484">
            <div>
              {{ assignment.projectName }}
            </div>
            ({{ assignment.dateRangeText }})
          </el-card>
        </el-col>
      </el-row>
    </el-form>

    <template v-slot:footer>
      <span class="dialog-footer">
        <cancel-button
          :text="$t('src.components.project.bryntumscheduler.projecteventcreateeditor.abbrechen')"
          @click="close"
        />
        <save-button
          :text="$t('src.components.project.bryntumscheduler.projecteventcreateeditor.bernehmen')"
          :loading="loading"
          @click="addEventToProjectCalendar"
        />
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  Dialog,
  Form,
  FormItem,
  Button,
  Select,
  Message,
  Checkbox,
  CheckboxGroup,
  InputNumber,
  Row,
  Col,
} from "element-ui";
import { moment } from "src/config/moment";
import { getDateRangesFromArray } from "../../../utils/getDateRangesFromArray";
import { mapGetters } from "vuex";

const defaultCheckedWeekdays = [1, 2, 3, 4, 5, 6, 7];

export default {
  name: "project-event-create-editor",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Dialog.name]: Dialog,
    [InputNumber.name]: InputNumber,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Select.name]: Select,
    [Message.name]: Message,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  props: {
    eventsCanOverlap: Boolean,
    getProjectName: Function,
    getAvailableProjects: Function,
    resourceRecord: Object,
    resourceId: String,
    resourceType: String,
    eventRecord: Object,
    projectId: String,
    visible: Boolean,
    triggerCalendarUpdate: Function,
    currentMonday: Date,
  },
  data() {
    const weekDaysValidator = (rule, value, callback) => {
      if (!this.formData.completeRemainingProject && !value.length) {
        callback(new Error("Bitte wählen Sie mindestens einen Tag aus"));
      }
      callback();
    };
    return {
      previousAssignments: [],
      availableProjects: [],
      formDataRules: {
        selectedProject: {
          required: true,
          message: "Bitte Projekt auswählen",
          trigger: "change",
        },
        checkedWeekDays: {
          validator: weekDaysValidator,
          trigger: "change",
        },
      },
      dayClicked: null,
      formData: {
        completeRemainingProject: false,
        // clickedDate: {},
        selectedProject: "",
        checkedWeekDays: [],
        disabledWeekDays: [],
        title: "",
        quantity: null,
      },
      loading: false,
      existingEventsDateRanges: [],
    };
  },
  methods: {
    /** Feature: when creating event on a day - check days between clicked day and Friday */
    setCheckedWeekDays() {
      const weekDay = this.dayClicked.weekday();
      const resourceType = this.resourceRecord.resourceType;
      let selectedProjectIsWorkshop = false;
      if (this.formData.selectedProject) {
        const selectedProject = this.availableProjects.find((item) => item.value === this.formData.selectedProject);
        selectedProjectIsWorkshop = selectedProject.isWorkshop;
      }
      let daysSelection = [];
      if (
        resourceType === "employee" ||
        resourceType === "subcontractor" ||
        // resourceType === "vehicle" ||
        selectedProjectIsWorkshop // #2496
      ) {
        if (weekDay <= 4) {
          // 4 - index of Friday
          // +1 - to include last array item
          daysSelection = defaultCheckedWeekdays.slice(weekDay, 4 + 1);
        } else {
          // Only clicked day
          daysSelection = [weekDay + 1];
        }
        daysSelection = daysSelection.filter((weekDayNum) => {
          return !this.isDayOverlapExistingEvents(moment(this.dayClicked).isoWeekday(weekDayNum));
        });
        console.log("daysSelection2", daysSelection);
        this.formData.completeRemainingProject = false;
      } else {
        this.formData.completeRemainingProject = true;
      }

      this.formData.checkedWeekDays = daysSelection;
    },
    initModal() {
      let dateClickedMs;
      if (this.eventRecord) {
        dateClickedMs = this.eventRecord.startDate.getTime();
      } else {
        // if modal is called from "plus" button at resource column - we don't have eventRecord. So we pick monday instead
        dateClickedMs = moment(this.currentMonday).toDate().getTime();
      }
      this.availableProjects = this.getAvailableProjects(dateClickedMs);
      const resourceEvents = window.resourceScheduler.eventStore.getEventsForResource(this.resourceId);
      this.existingEventsDateRanges = resourceEvents.map((item) =>
        moment.range(item.data.startDate, item.data.endDate).snapTo("day")
      );
      this.formData.title = this.resourceRecord.name;
      const momentDate = moment(dateClickedMs).startOf("day");
      this.dayClicked = momentDate;
      this.$nextTick(() => {
        this.setCheckedWeekDays();
      });

      if (this.formData.selectedProject) {
        const project = this.availableProjects.find((item) => item.id === this.projectId);
        if (project) {
          const projectRange = moment.range([project.dateRange[0], moment(project.dateRange[1])]);
          // if clicked day is not within previously selected project range - drop selected project.
          if (!momentDate.within(projectRange)) {
            this.formData.selectedProject = "";
            this.$refs.eventForm.resetFields();
          }
        }
      }
      // check if selectedProject is still available (when changing view date range) - discard if not available
      if (
        this.formData.selectedProject &&
        !this.availableProjects.some((item) => item.value === this.formData.selectedProject)
      ) {
        this.formData.selectedProject = "";
      }
      this.$nextTick(() => {
        if (this.formData.selectedProject) {
          this.setCheckedWeekDays();
          // setTimeout(() => {
          this.setDisabledAndCheckedWeekdays(this.formData.selectedProject);
          // }, 200);
        }
      });
      // }

      this.fetchPreviousAssignments();
    },
    async addEventToProjectCalendar() {
      this.$refs.eventForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let startDate;
            if (this.eventRecord) {
              startDate = this.eventRecord.startDate;
            } else {
              startDate = moment(this.dayClicked).isoWeekday(1);
            }
            await this.handleCreateEvent(
              startDate,
              this.formData.checkedWeekDays,
              this.formData.selectedProject,
              this.formData.completeRemainingProject
            );
          } catch (err) {
            if (err.response && err.response.data) {
              Message.error(err.response.data.message);
            }
            throw err;
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
    hitsAnyStatusOrProject(upperResourceType, upperResourceId, newRange) {
      // supplies don't care. they're available =)
      if (upperResourceType === "supply" || upperResourceType === "subcontractor" || this.eventsCanOverlap) {
        return false;
      }
      // true - event change shall not be approved
      if (newRange.length !== 2) {
        console.log("GOT: ", newRange);
        throw new Error("newRange should be an array of two dates");
      }
      //get all unavailable Events
      const unavailableEvents = window.resourceScheduler.eventStore.getEventsForResource(upperResourceId);
      const currentRange = moment.range(newRange);
      const result = unavailableEvents.some((event) => {
        // TODO: set end of status event to the end of the day when create status (check out datepicker)
        // because status events date range that is set in user editing page ends in the beginning of the day
        // we have to set it to the end of the day here manually
        const range = moment.range(moment(event.startDate), moment(event.endDate));
        return range.overlaps(currentRange, { adjacent: true });
      });
      return result;
    },
    async handleCreateEvent(dateClicked, weekdays, projectId, completeRemainingProject) {
      // Array of selected dates of new event
      const newDays = weekdays.sort().map((weekday) => this.getDateByWeekday(dateClicked, weekday));
      //get the assigned project
      const project = this.availableProjects.find((item) => item.value === projectId);
      let dateRanges;
      if (completeRemainingProject) {
        dateRanges = [[moment(dateClicked), moment(project.dateRange[1])]];
      } else {
        dateRanges = getDateRangesFromArray(newDays);
      }
      if (Array.isArray(dateRanges[0])) {
        for (let idx = 0; idx < dateRanges.length; idx++) {
          let current = dateRanges[idx];
          // test if new range is within project range
          if (!this.isInProjectRange(project, current)) {
            //restore calendar
            Message({
              message: "Sie müssen innerhalb des Projektes bleiben.",
              type: "warning",
            });
            return;
          }
          // test of any status or project is in the new range
          if (this.hitsAnyStatusOrProject(this.resourceType, this.resourceId, current)) {
            Message({
              message: "Sie kollidieren mit einem Status oder Projekt",
              type: "warning",
            });
            return;
          }
        }
      } else {
        if (!this.isInProjectRange(project, dateRanges)) {
          Message({
            messge: "Sie müssen innerhalb des Projektes bleiben.",
            type: "warning",
          });
          return;
        }
        // test of any status or project is in the new range
        if (this.hitsAnyStatusOrProject(this.resourceType, this.resourceId, dateRanges)) {
          Message({
            message: "Sie kollidieren mit einem Status oder Projekt",
            type: "warning",
          });
          return;
        }
      }
      const eventPostObjects = dateRanges.map((item) => ({
        title: this.resourceRecord.displayName,
        resourceType: this.resourceType,
        start: item[0].format("YYYY-MM-DD"),
        end: item[1].format("YYYY-MM-DD"),
        resourceId: this.resourceId,
        projectId: projectId,
        quantity: this.formData.quantity,
      }));
      // # all checks passed #
      await this.addProjectEvents(eventPostObjects);
    },
    async addProjectEvents(postObjects) {
      const resourceTypeToGerman = {
        employee: "Mitarbeiter",
        machine: "Maschine",
        vehicle: "KFZ",
        rhb: "RHB",
      };
      try {
        const promises = postObjects.map((formBody) =>
          this.axios
            .post("/api/project-events", formBody, {
              params: { mode: this.getGranularityMode(formBody.resourceType) },
            })
            .catch((err) => {
              if (err.response.status === 403) {
                if (err.response.data.code === 101) {
                  Message({
                    message: "Ressource kann einem Projekt nicht doppelt zugewiesen werden",
                    type: "warning",
                  });
                } else if (err.response.data.code === 1) {
                  Message({
                    type: "error",
                    message: err.response.data.message,
                  });
                } else {
                  const resourceType = postObjects[0].resourceType;
                  Message({
                    type: "error",
                    message: resourceTypeToGerman[resourceType] + " ist im angegebenen Zeitraum beschäftigt",
                  });
                }
              } else if (err.response.data.message) {
                Message.error(err.response.data.message);
              }
              throw err;
            })
        );
        await Promise.all(promises);
        this.$props.triggerCalendarUpdate();
        this.close();
      } catch (error) {
        throw error;
      }
    },
    isDayOverlapExistingEvents(day) {
      const result = this.existingEventsDateRanges.some((range) => range.contains(day));
      return result;
    },
    discard() {
      //restore default choice
      this.formData.disabledWeekDays = [];
      this.formData.checkedWeekDays = [];
      this.formData.completeRemainingProject = false;
      this.formData.selectedProject; // preserve previously selected project
      this.formData.title = "";
      this.formData.quantity = null;
      this.dayClicked = null;
    },
    close() {
      this.$emit("close");
      this.formData.quantity = null;
      this.availableProjects = [];
      this.previousAssignments = [];
    },
    getDateByWeekday(droppedDate, weekDayNumber) {
      let date = moment(droppedDate).isoWeekday(weekDayNumber);
      return date;
    },
    isInProjectRange(project, dateRange) {
      //create range in correct format
      let newRange = moment.range(dateRange);

      const projectDateRange = moment.range(moment(project.dateRange[0]), moment(project.dateRange[1])).snapTo("day");
      let isInProjectRange = true;
      for (let day of newRange.by("day")) {
        if (!day.within(projectDateRange)) {
          isInProjectRange = false;
          break;
        }
      }
      return isInProjectRange;
    },
    setDisabledAndCheckedWeekdays(projectId) {
      const eventsCanOverlap =
        this.resourceRecord.resourceType === "supply" ||
        this.resourceRecord.resourceType === "subcontractor" ||
        this.eventsCanOverlap;
      console.log("setDisabledAndCheckedWeekdays projectId", projectId);

      const project = this.availableProjects.find((item) => item.value === projectId);
      if (project && project.isWorkshop) {
        this.formData.completeRemainingProject = false;
      }
      if (project && project.dateRange && project.dateRange.length) {
        const projectDateRange = moment.range(project.dateRange).snapTo("day");
        const mondayOfClickedDay = moment(this.dayClicked).isoWeekday(1);
        const weekDayDates = defaultCheckedWeekdays.map((weekDay) =>
          this.getDateByWeekday(mondayOfClickedDay, weekDay)
        );
        let disabledWeekDays;

        if (this.formData.completeRemainingProject) {
          disabledWeekDays = defaultCheckedWeekdays.slice();
        } else {
          // get disabled weekday numbers if date is not within project range
          disabledWeekDays = defaultCheckedWeekdays.reduce((arr, current, idx) => {
            if (
              !weekDayDates[idx].within(projectDateRange) ||
              (!eventsCanOverlap && this.isDayOverlapExistingEvents(weekDayDates[idx]))
            ) {
              arr.push(current);
            }
            return arr;
          }, []);
        }
        this.formData.disabledWeekDays = disabledWeekDays;
        // uncheck disabled checkboxes if there are any
        let checkedWeekDays;
        if (this.formData.checkedWeekDays.length) {
          checkedWeekDays = this.formData.checkedWeekDays.slice();
        } else {
          const weekDay = this.dayClicked.weekday();
          let daysSelection = [];
          if (weekDay <= 4) {
            // 4 - index of Friday
            // +1 - to include last array item
            daysSelection = defaultCheckedWeekdays.slice(weekDay, 4 + 1);
          } else {
            // Only clicked day
            daysSelection = [weekDay + 1];
          }
          if (!eventsCanOverlap) {
            daysSelection = daysSelection.filter((weekDayNum) => {
              return !this.isDayOverlapExistingEvents(moment(this.dayClicked).isoWeekday(weekDayNum));
            });
          }
          checkedWeekDays = daysSelection;
          // if (this.resourceRecord.resourceType === "employee") {
          // } else {
          //   checkedWeekDays = defaultCheckedWeekdays.slice();
          // }
        }
        this.formData.checkedWeekDays = checkedWeekDays.filter((item) => !disabledWeekDays.includes(item));
      } else {
        const weekDay = this.dayClicked.weekday();
        let daysSelection = [];
        if (weekDay <= 4) {
          // 4 - index of Friday
          // +1 - to include last array item
          daysSelection = defaultCheckedWeekdays.slice(weekDay, 4 + 1);
        } else {
          // Only clicked day
          daysSelection = [weekDay + 1];
        }
        daysSelection = daysSelection.filter((weekDayNum) => {
          return !this.isDayOverlapExistingEvents(moment(this.dayClicked).isoWeekday(weekDayNum));
        });
        this.formData.checkedWeekDays = daysSelection;
      }
    },
    async fetchPreviousAssignments() {
      try {
        const end = moment(this.dayClicked).startOf("week").format("YYYY-MM-DD");
        const start = moment(this.dayClicked).startOf("week").subtract(3, "days").format("YYYY-MM-DD");
        const response = await this.axios.get(`/api/project-events/project`, {
          params: {
            resourceId: this.resourceId,
            start: start,
            end: end,
          },
        });
        if (response.data.length) {
          this.previousAssignments = response.data.map((event) => {
            return {
              projectName: this.getProjectName(event.projectId) || event.projectId,
              dateRangeText: `${moment(event.start).format("dd, DD.MM.YY")} - ${moment(event.end).format("dd, DD.MM.YY")}`,
            };
          });
        }
      } catch (error) {
        throw error;
      }
    },
  },
  watch: {
    resourceRecord(newVal, oldVal) {
      if (newVal) {
        this.initModal();
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
    "formData.selectedProject": function (newVal, oldVal) {
      this.setCheckedWeekDays();
      this.setDisabledAndCheckedWeekdays(newVal);
    },
    /**
     * completeRemainingProject = true - set date range till the end of the project
     * completeRemainingProject = false - pick days from current week
     */
    "formData.completeRemainingProject": function (newVal, oldVal) {
      if (newVal === true) {
        this.formData.checkedWeekDays = [];
        this.formData.disabledWeekDays = defaultCheckedWeekdays.slice();
      } else {
        let momentDate;
        if (this.eventRecord) {
          momentDate = moment(this.eventRecord.startDate);
        } else {
          // if modal is called from "plus" button at resource column - we don't have eventRecord. So we pick monday instead
          momentDate = moment().isoWeekday(1);
        }
        // (this.eventRecord) check is for fallback when modal gets hidden to not throw an exception
        this.formData.disabledWeekDays = [];
        const weekDay = momentDate.weekday();
        let daysSelection = [];
        if (!this.resourceRecord) {
          return;
        }
        // // if employee - preselect from clicked day to friday
        // if (this.resourceRecord.resourceType === "employee") {
        //   if (weekDay <= 4) {
        //     // 4 - index of Friday
        //     // +1 - to include last array item
        //     daysSelection = defaultCheckedWeekdays.slice(weekDay, 4 + 1);
        //   } else {
        //     // from Friday to clicked day
        //     daysSelection = defaultCheckedWeekdays.slice(4, weekDay + 1);
        //   }
        //   // for the rest resources - preselect from clicked day till the end of week
        // } else {
        //   daysSelection = defaultCheckedWeekdays.slice(weekDay);
        // }
        // this.setCheckedWeekDays();
        this.setDisabledAndCheckedWeekdays(this.formData.selectedProject);
        // this.formData.checkedWeekDays = daysSelection;
      }
    },
  },
  computed: {
    ...mapGetters("granularitySettings", ["getGranularityMode"]),
    unitName() {
      if (this.resourceRecord && this.resourceRecord.unitName) {
        return this.resourceRecord.unitName;
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>
